.records {
  margin: 30px 0px;
  padding: 0px 30px;
}
.records .btns {
  display: flex;
  gap: 40px;
}
.records .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.records .heading .search > input {
  padding: 10px;
  border: 1px solid #ccc;
}
.records > .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    width: 100%;
}
.records > .spinner > svg{
  animation: spin 2s linear infinite;
  font-size: 5rem;
}
@-moz-keyframes spin {
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.records .table_responsive {
  width: 100%;
  /* min-height: 40vh; */
  margin: 10px 0px;
  /* overflow-y: hidden;
    overflow-x: auto; */
  padding-bottom: 200px;
}
.records .table_responsive table {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 15px;
  font-size: 13px;
}
.records > .table_responsive > table > thead {
  background: #ffffff;
  position: sticky;
  top: 0px;
}
.records > .table_responsive > table > tbody > tr {
  border: 1px solid #000000c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #00000040;
  border-radius: 10px;
  background: #f2f2f2;
  height: 40px;
  margin: 20px 0px;
}
.records > .table_responsive > table > thead > tr > th {
  white-space: nowrap;
  padding: 20px;
  border: 1px solid #cccccc6e;
}
.records > .table_responsive .table tr td {
  border: 1px solid #cccccc6e;
  /* white-space: nowrap; */
  padding: 10px;
  min-width: max-content;
  max-width: 300px;
  word-wrap: break-word;
}
.records > .table_responsive .table tr td .actions_list{
  display: flex;
  width: max-content;
}
.records > .table_responsive .table tr td .linkIcon {
  font-size: 30px;
  color: #145aa0;
  text-decoration: none;
}

.records > .table_responsive .table tr td button.linkIcon {
  font-size: 30px;
  border: none;
  outline: none;
  color: #e60a0a;
  text-decoration: none;
  cursor: pointer;
}
.records .table_responsive .table th .filter {
  background: none;
  width: max-content;
  padding: 0;
  margin: 0px 5px;
  border: none;
  outline: none;
  font-size: 25px;
  color: #145aa0;
  vertical-align: middle;
  cursor: pointer;
}