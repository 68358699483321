* {
  margin : 0;
}
body{
  font-family: Arial, Helvetica, sans-serif;
  max-width: 100%;
}
.btn {
  padding: 10px 15px;
  background: #145aa0;
  color: #ffffff;
  border: 2px solid #a6c3f8;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
}