.report {
    margin: 30px 0px;
    padding: 0px 30px;
}
.report > .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    width: 100%;
}
.report > .spinner > svg{
  animation: spin 2s linear infinite;
  font-size: 5rem;
}
@-moz-keyframes spin {
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
/* .report .main {
  padding: 50px 20px;
  margin: 20px 0px;
  background-color: #f1f1f1;
  border-radius: 10px;
} */
/* .report .main .profile {
  display: flex;
  justify-content: space-between;
} */
/* .report .main .profile img{
  width: 150px;
  margin: 0px 20px;
  object-fit: contain;
  border-radius: 50%;
} */
/* .report .main .profile .user h4 {
  margin: 6px 0px;
  color: #858484;
}
.report .main .profile .user p {
  margin: 6px 0px;
  color: #858484;
} */
.report .main .report_table {
  overflow-y: hidden;
  overflow-x: auto;
}
/* .report .main .report_table table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 13px;
} */
.report .main > .report_table > table > thead {
  background: #ffffff;
  position: sticky;
  top: 0px;
}
/* .report .main > .report_table > table > thead > tr > th {
  white-space: nowrap;
  padding: 20px;
  border: 1px solid #cccccc6e;
} */
/* .report .main > .report_table > table > tbody > tr > td {
  padding: 15px 10px;
  min-width: 60px;
  max-width: 400px;
  border: 1px solid #cccccc6e;
} */
/* .report .main .footer p {
  border: 1px solid #696969;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0px;
  background-color: #cccccc41;
  color: #000;
} */
