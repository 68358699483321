.form .header {
    width: 100%;
    background-image: url('https://www.3timpexglobal.com/wp-content/uploads/2022/06/3T-Ship-2.jpg');
}
.form .header .text {
    width: 100%;
    min-height: 300px;
    padding: 100px 40px;
    background: rgba(000, 000, 000, 0.7);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4em;
}
.form .main {
    padding: 50px;
}

.form .main .asterix {
    color: #c20000;
    font-size: large;
}

.form .main .tabs{
    display:flex;
    justify-content: flex-start;
    gap: 10px 15px;
    width: 100%;
    flex-wrap: wrap;
    padding: 40px 0px;
}

.form .main .tabs button, .form .main .nav button {
    padding: 18px;
    background: #323232;
    color: #fff;
    outline: none;
    border: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: lighter;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.form .main .tabs button.active, .form .main .tabs button:hover, .form .main .nav button:hover {
    background: #d0cece;
    color: #686868;
}
.form .main .nav button:disabled {
    background: #d0cece;
    color: #686868;
    cursor: not-allowed;
}

.form .main .formitem {
    margin: 40px 0px;
}

.form .main .formitem label {
    width: 100%;
    display: block;
    color: #686868;
    margin: 10px 0px;
}

.form .main .formitem .label {
    font-weight: bold;
}

.form .main .formitem .label::after {
    content: " *";
    color: #c20000;
    font-size: large;
}

.form .main .formitem .textbox {
    width: 100%;
    display: block;
    padding: 15px;
}
.form .main .formitem .options {
    margin: 15px 0px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.form .main .formitem .options .option {
    display: flex;
    gap: 10px;
    color: #686868;
}

.form .main .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px;
}

.error_msg {
    color: #ec0303;
    display: flex;
    align-items: center;
    gap: 10px;
}

.success_msg {
    color: #03ec8b;
    display: flex;
    align-items: center;
    gap: 10px;
}