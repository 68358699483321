.analyze {
    margin: 30px 0px;
    padding: 0px 30px;
}
.analyze .btns {
    display: flex;
    gap: 40px;
}
.analyze .form_uploader{
    padding: 30px 0px;
}
.analyze .form_uploader input {
    padding: 10px;
}
.analyze .form_uploader button {
    padding: 10px 15px;
    background: #145aa0;
    color: #ffffff;
    border: 2px solid #a6c3f8;
    border-radius: 10px;
    cursor: pointer;
}
.analyze > .form_uploader > .spinner svg {
    animation: spin 2s linear infinite;
    font-size: 2rem;
}
@-moz-keyframes spin {
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    to {transform:rotate(360deg);}
}


.analyze .table_responsive {
    width: 100%;
    /* min-height: 40vh; */
    margin: 10px 0px;
    /* overflow-y: hidden;
    overflow-x: auto; */
    padding-bottom: 200px;
}
.analyze .table_responsive table {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0px 15px;
    font-size: 13px;
}
.analyze > .table_responsive > table > thead {
    background: #ffffff;
    position: sticky;
    top: 0px;
}
.analyze > .table_responsive > table > tbody > tr {
    border: 1px solid #000000c4;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px #00000040;
    border-radius: 10px;
    background: #F2F2F2;
    height: 40px;
    margin: 20px 0px;
}
.analyze > .table_responsive > table > thead > tr > th {
  white-space: nowrap;
  padding: 20px;
  border: 1px solid #cccccc6e;
}
.analyze > .table_responsive .table tr td {
    border: 1px solid #cccccc6e;
    /* white-space: nowrap; */
    padding: 10px;
    min-width: max-content;
    max-width: 300px;
    word-wrap:break-word;
}
.analyze > .table_responsive .table tr td .linkIcon{
    font-size: 30px;
    color: #145aa0;
    text-decoration: none;
}