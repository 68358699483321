.index {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}
.index img {
    width: 200px;
    object-fit: contain;
    border-radius: 50%;
    margin: 0px 50px;
}

.index .btns {
    display: flex;
    flex-direction: column;
    gap: 40px;
}